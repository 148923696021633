export default {
	"message": {
		"main": {
			"header": {
				"docs": "docs",
				"about": "about",
				"dashboard": "dashboard",
				"templates": "templates",
				"assets": "assets",
				"connect-wallet": "connect wallet",
				"select-data-chain": "Select data chain"
			},
			"body": {
				"title": "CO2.Storage",
				"sub-title": "Free decentralized storage for Environmental Assets on",
				"sub-title-colored": "IPFS and Filecoin",
				"stats": "stats",
				"view-all-stats": "view all stats",
				"cumulative-CO2": "Cumulative Tonnes of CO2e",
				"cumulative-CO2-description": "Total metric tonnes of CO2e represented",
				"cumulative-uploads": "Cumulative uploads",
				"cumulative-uploads-description": "Total amount of environmental assets stored on the dweb",
				"average-uploads-per-month": "Average uploads per month",
				"average-uploads-per-month-description": "Average monthly uploads",
				"cumulative-cids": "Cumulative CIDs",
				"cumulative-cids-description": "Total amount of CIDs created from environmental assets uploads",
				"cumulative-environmental-asset-templates": "Cumulative Environmental Asset Templates",
				"cumulative-environmental-asset-templates-description": "Total amount of templates created from environmental assets uploads"
			}
		},
		"dashboard": {
			"body": {
				"my-environmental-assets": "My Environmental Assets",
				"my-environmental-assets-description": "List of Environmental Assets I've uploaded",
				"my-environmental-asset-templates": "My Environmental Asset Templates",
				"my-environmental-asset-templates-description": "List of Environmental Asset Templates I've uploaded",
				"no-assets-found": "No environmental assets found.",
				"no-asset-templates-found": "No environmental asset templates found.",
				"loading-data-wait": "Loading data. Please wait.",
				"name": "Name",
				"cid": "CID",
				"search-by-schema-cid": "Search by template CID",
				"search-by-schema-name": "Search by template name",
				"search-by-asset-cid": "Search by asset CID",
				"search-by-asset-name": "Search by asset name",
				"keyword-search": "Keyword search",
				"sign-something": "Sign '{something}'",
				"signed-by": "Signed by '{by}'",
				"sign-cid": "Sign CID",
				"signed-cid": "Signed CID",
				"signatures": "Signatures",
				"view-signatures": "Click to see signatures",
				"ipld": "IPLD"
			}
		},
		"about": {
			"body":  {
				"title": "CO2.Storage",
				"sub-title": "Free decentralized storage for Environmental Assets on",
				"sub-title-colored": " IPFS and Filecoin",
				"about-description-0": "Try it out",
				"about-description": "The Filecoin Green team is developing CO2.Storage to provide a web3 storage solution for environmental assets based on content addressed data schemas.",
				"about-description-1": "This project is the continuation of the idea posted in the Filecoin Green Tools repo:",
				"about-description-2": "CO2.Storage maps inputs to base data schemas",
				"about-description-3": "for off-chain data (like metadata, images, attestation documents, and other assets) to promote the development of standard data schemas for environmental assets.",
				"about-description-4": "With IPLD DAGs, data is content addressed using IPFS, meaning the URI pointing to a piece of data (“ipfs://…”) is completely unique to that data (using a", 
				"about-description-5": ", or CID). CIDs can be used for environmental assets and metadata to ensure the asset forever actually refers to the intended data (eliminating things like double counting, and making it trustlessly verifiable what content an asset is associated with). These standard, content addressed, data schemas will also enable more seamless cross-referencing for missing data and meta-analysis of different assets/credits, as well as help expedite the development of new forms of methodologies, supply, and marketplaces.",
				"about-description-6": "This project is in",
				"about-description-7": "alpha",
				"about-description-8": "and while many features can be considered stable, we are waiting until we are feature complete to fully launch. The Filecoin Green team is actively working on this project and welcomes contributions from the community",
				"about-description-9":  "to test out the current functionalities",
				"about-description-10": "We are seeking input to encourage standardization and reduce fragmentation of data schemas across the Refi space",
				"about-description-11": "To publicly discuss and crowd-source data schemas for CO2.Storage, please visit:  ",
				"about-description-12": "We are also actively seeking input on features from industry participants and interested parties. Please provide your contact information if you'd like to receive updates:",
				"about-description-13": "Data made available through CO2.storage is provided by users, and not verified by Filecoin Green",
				"about-description-14": "Made with",
				"about-description-15": "by the",
				"about-description-16": "team",
			}

		},
		"profile": {
			"my-ui-theme": "My UI Theme",
			"my-ui-theme-description": "My UI Theme is \"{themeName}\"",
			"my-estuary-key": "My Estuary Key",
			"my-api-token": "My API token",
			"change": "Change",
			"create-new": "Create new",
			"revoke": "Revoke",
			"no-other-themes-available": "There are currently no other themes available.",
			"no-estuary-key-created": "No Estuary key has been created for this account yet.",
			"no-api-token-created": "No API token has been created for this account yet.",
			"valid-until": "Valid until:",
			"contributor-name": "Name",
			"default-license": "Default license",
			"save": "Save",
			"profile-not-updated": "Profile is NOT updated!",
			"profile-updated": "Profile is successfully updated!"
		},
		"assets": {
			"select-environmental-asset": "Select Environmental Asset",
			"select-environmental-asset-template": "Select Environmental Asset Template",
			"no-asset-templates-found": "No environmental asset templates found.",
			"no-assets-found": "No environmental assets found.",
			"loading-data-wait": "Loading data. Please wait.",
			"search-by-creator-wallet": "Search by creator wallet",
			"search-by-schema-cid": "Search by template CID",
			"search-by-schema-name": "Search by template name",
			"search-by-base-schema": "Search by base schema",
			"create-environmental-asset": "Create Asset",
			"environmental-asset-name": "Asset name",
			"asset-cid": "Asset CID",
			"creator": "Creator",
			"cid": "CID",
			"name": "Name",
			"base": "Base",
			"used": "Used",
			"forks": "Forks",
			"search-by-creator-wallet": "Search by creator wallet",
			"search-by-schema-cid": "Search by template CID",
			"search-by-schema-name": "Search by template name",
			"search-by-base-schema": "Search by base schema",
			"create": "Create",
			"adding-images-and-documents-to-ipfs": "Adding Images and Documents to IPFS",
			"uploading-images-and-documents": "Uploading",
			"creating-asset": "Creating asset",
			"empty-asset": "Empty asset",
			"enter-environmental-asset-data": "Please enter environmental asset data",
			"asset-created": "Environmental asset is created! Updating chained data structures.",
			"generic-asset-name": "Asset based on {template} template created by {wallet}",
			"create-new-version": "Create new version?",
			"asset-description": "Description",
			"loading-asset": "Loading asset. Please wait.",
			"waiting-bacalhau-job-start": "Waiting for Bacalhau job to start...",
			"bacalhau-job-started": "Bacalhau job is started. When the job completes successfully, the job CID will be displayed.",
			"asset-metadata": "Asset metadata",
			"provenance-info": "Provenance information",
			"checking-template-validity": "Checking if provided type/template is valid...",
			"invalid-template": "Invalid template CID provided.",
			"checking-asset-validity": "Checking if provided asset is valid...",
			"invalid-asset": "Invalid asset CID provided.",
			"invalid-chain-index": "The index data chain can not determined for the provided template CID.",
			"asset-created-thanks": "Thank you for creating asset",
			"no-provenance-info": "There is no provenance information available for this record yet."
		},
		"schemas": {
			"search-existing-environmental-asset-templates": "Search existing environmental asset templates",
			"no-asset-templates-found": "No environmental asset templates found.",
			"loading-data-wait": "Loading data. Please wait.",
			"search-by-creator-wallet": "Search by creator wallet",
			"search-by-schema-cid": "Search by template CID",
			"search-by-schema-name": "Search by template name",
			"search-by-base-schema": "Search by base schema",
			"creator": "Creator",
			"cid": "CID",
			"name": "Name",
			"base": "Base",
			"used": "Used",
			"forks": "Forks",
			"create-environmental-asset-template": "Create or clone environmental asset template",
			"environmental-asset-template-name": "Environmental asset template name",
			"create-new-template": "Create new template",
			"create": "Create",
			"drag-and-drop-documents": "Drag and drop documents to here to upload.",
			"drag-and-drop-images": "Drag and drop images to here to upload.",
			"upload-not-allowed": "Uploading is not allowed",
			"upload-not-allowed-description": "Uploading is not allowed when creating a template.",
			"empty-schema": "Empty schema",
			"empty-schema-definition": "Please add environmental asset template definition",
			"template-created": "Environmental asset template is created! Updating chained data structures.",
			"new-schema": "New schema",
			"adding-new-schema": "Adding a new template. Please wait.",
			"create-new-version": "Create new version?",
			"base-schema": "Base Template",
			"schema-description": "Description",
			"loading-schema": "Loading template. Please wait."
		},
		"form-elements": {
			"remove-item-q": "Are you sure you want to remove this item?",
			"job-uuid": "Job UUID",
			"job-cid": "Job CID",
			"job-still-running": "Bacalhau job is still running. Please wait..."
		},
		"mixins": {
			"clipboard": {
				"copy-to-clipboard": {
					"success": "Success!",
					"error": "Error!",
					"copied": "Content is copied to clipboard!",
					"not-copied": "Content is NOT copied to clipboard!"
				}
			}
		},
		"shared": {
			"initial-loading": "Please wait for the data structures to be initialized. This may take a few seconds if you are connecting the wallet for the first time.",
			"initializing-ipfs-node": "Initializing IPFS node",
			"wallet-not-connected": "Wallet not connected",
			"wallet-not-connected-description": "Please connect your wallet in order to see your environmental assets and templates.",
			"created": "Created",
			"chained-data-updated": "Chained data updated",
			"chained-data-updated-description": "Chained data structures are successfully updated",
			"loading-something": "Loading {something}",
			"success": "Success!",
			"error": "Error",
			"error_": "Error: {err}",
			"change": "Change",
			"method": "Method",
			"verifying-contract": "Verifying contract",
			"chain-id": "Chain Id",
			"signer": "Signer",
			"cid": "CID",
			"signature": "Signature",
			"signature-v": "v",
			"signature-r": "r",
			"signature-s": "s",
			"verified": "Verified",
			"empty-recordset": "Empty recordset",
			"total-size": "Total size:",
			"signing-message": "Signing message {message}",
			"signed": "Signed",
			"message-signed": "Provenance message is successfully signed",
			"create-another": "Create another",
			"install-metamask-and-connect-wallet": "Please make sure you have installed Metamask and connect your wallet to verify signature.",
			"non-ethereum-browser-detected": "This form requires a wallet to contribute data. Try using Metamask or Brave."
		},
		"helpers": {
			"contributor": {
				"title": "Contribution data",
				"name": "Contributor name",
				"license": "Data license",
				"notes": "Notes"
			}
		}
	}
}
